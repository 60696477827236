// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import InvoiceBilling from "../../blocks/invoicebilling/src/InvoiceBilling";
import Cfavinodeintegration1 from "../../blocks/cfavinodeintegration1/src/Cfavinodeintegration1";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Cfdesignchange5 from "../../blocks/cfdesignchange5/src/Cfdesignchange5";


const routeMap = {
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
Cfavinodeintegration1:{
 component:Cfavinodeintegration1,
path:"/Cfavinodeintegration1"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Cfdesignchange5:{
 component:Cfdesignchange5,
path:"/Cfdesignchange5"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },

  Alert: {
    component: AlertBlock,
    path: "*/Alert",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <BuilderProvider>
        <View style={{ height: '100vh', width: '100vw' }}>
          <TopNav />
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
        </View>
      </BuilderProvider>
    );
  }
}

export default App;